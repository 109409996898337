import React, { FC } from 'react'
import { Row as ANTDRow, Col as ANTDCol } from 'antd'
import { GlobalGrid } from '../../../style/styled/antd/GlobalGridMini.style'
import { RowProps } from 'antd/lib/row'

export const Row: FC<RowProps & React.RefAttributes<HTMLDivElement>> = (props) => {
  return (
    <>
      <style jsx global>
        {GlobalGrid}
      </style>
      <ANTDRow {...props} />
    </>
  )
}
export const Col = ANTDCol
