import css from 'styled-jsx/css'

export const GlobalGrid = css.global`
  .ant-row {
    display: flex;
    flex-flow: row wrap;
  }
  .ant-row::before,
  .ant-row::after {
    display: flex;
  }
  .ant-row-start {
    justify-content: flex-start;
  }
  .ant-row-center {
    justify-content: center;
  }
  .ant-row-end {
    justify-content: flex-end;
  }
  .ant-row-space-between {
    justify-content: space-between;
  }
  .ant-row-space-around {
    justify-content: space-around;
  }
  .ant-row-top {
    align-items: flex-start;
  }
  .ant-row-middle {
    align-items: center;
  }
  .ant-row-bottom {
    align-items: flex-end;
  }
  .ant-col {
    position: relative;
    max-width: 100%;
    min-height: 1px;
  }
  .ant-col-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-0 {
    display: none;
  }
  .ant-col-xs-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xs-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xs-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xs-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xs-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xs-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xs-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xs-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xs-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xs-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xs-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xs-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xs-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xs-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xs-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xs-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xs-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xs-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xs-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xs-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xs-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xs-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xs-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xs-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xs-0 {
    display: none;
  }
  @media (min-width: 576px) {
    .ant-col-sm-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-sm-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ant-col-sm-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ant-col-sm-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ant-col-sm-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ant-col-sm-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ant-col-sm-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ant-col-sm-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ant-col-sm-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ant-col-sm-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ant-col-sm-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ant-col-sm-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ant-col-sm-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ant-col-sm-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ant-col-sm-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ant-col-sm-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ant-col-sm-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ant-col-sm-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ant-col-sm-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ant-col-sm-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ant-col-sm-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ant-col-sm-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ant-col-sm-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ant-col-sm-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ant-col-sm-0 {
      display: none;
    }
  }
  @media (min-width: 768px) {
    .ant-col-md-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-md-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ant-col-md-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ant-col-md-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ant-col-md-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ant-col-md-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ant-col-md-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ant-col-md-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ant-col-md-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ant-col-md-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ant-col-md-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ant-col-md-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ant-col-md-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ant-col-md-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ant-col-md-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ant-col-md-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ant-col-md-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ant-col-md-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ant-col-md-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ant-col-md-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ant-col-md-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ant-col-md-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ant-col-md-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ant-col-md-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ant-col-md-0 {
      display: none;
    }
  }
  @media (min-width: 992px) {
    .ant-col-lg-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-lg-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ant-col-lg-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ant-col-lg-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ant-col-lg-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ant-col-lg-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ant-col-lg-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ant-col-lg-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ant-col-lg-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ant-col-lg-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ant-col-lg-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ant-col-lg-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ant-col-lg-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ant-col-lg-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ant-col-lg-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ant-col-lg-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ant-col-lg-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ant-col-lg-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ant-col-lg-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ant-col-lg-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ant-col-lg-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ant-col-lg-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ant-col-lg-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ant-col-lg-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ant-col-lg-0 {
      display: none;
    }
  }
  @media (min-width: 1200px) {
    .ant-col-xl-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-xl-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ant-col-xl-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ant-col-xl-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ant-col-xl-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ant-col-xl-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ant-col-xl-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ant-col-xl-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ant-col-xl-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ant-col-xl-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ant-col-xl-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ant-col-xl-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ant-col-xl-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ant-col-xl-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ant-col-xl-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ant-col-xl-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ant-col-xl-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ant-col-xl-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ant-col-xl-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ant-col-xl-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ant-col-xl-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ant-col-xl-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ant-col-xl-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ant-col-xl-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ant-col-xl-0 {
      display: none;
    }
  }
  @media (min-width: 1600px) {
    .ant-col-xxl-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-xxl-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ant-col-xxl-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ant-col-xxl-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ant-col-xxl-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ant-col-xxl-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ant-col-xxl-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ant-col-xxl-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ant-col-xxl-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ant-col-xxl-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ant-col-xxl-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ant-col-xxl-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ant-col-xxl-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ant-col-xxl-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ant-col-xxl-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ant-col-xxl-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ant-col-xxl-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ant-col-xxl-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ant-col-xxl-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ant-col-xxl-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ant-col-xxl-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ant-col-xxl-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ant-col-xxl-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ant-col-xxl-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ant-col-xxl-0 {
      display: none;
    }
  }
  .ant-row-rtl {
    direction: rtl;
  }
`
